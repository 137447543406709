export const changeTheme = (theme) => {
    return {
        type: "CHANGE_THEME",
        payload: theme
    }
}

export const changewhite = () => {
    return {
        type: "CHANGE_WHITE"
    }
}
